export interface BridgeChain {
  chainId: number
  name: string
  symbol: string
  address?: string
}

export enum BridgeType {
  KRC_BSC = 'krc_bsc',
  BSC_KRC = 'bsc_krc',
}

export const KAI_ADDRESS = '0xD9Ec3ff1f8be459Bb9369b4E79e9Ebcf7141C093'
export const BNB_ADDRESS = '0xB8c77482e45F1F44dE1745F52C74426C631bDD52'

export const KRC_BDS_ADDRESS = '0x8ee37091d5fCcabAE183430208AF5608F77A48Ee'
export const BSC_BDS_ADDRESS = '0xDD93B700982953086BeaF3F71a821cF23eA248F7'
export const BDS_IN_KAI_ADDRESS = '0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b'
export const BDS_IN_BSC_ADDRESS = '0x030ce78aa5be014976bca9b8448e78d1d87fce0b'

export const supportedChains: BridgeChain[] = [
  {
    chainId: 1,
    name: 'KardiaChain',
    symbol: 'KAI',
    address: KAI_ADDRESS,
  },
  {
    chainId: 56,
    name: 'Binance Smart Chain',
    symbol: 'BSC',
    address: BNB_ADDRESS,
  },
]
