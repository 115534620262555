import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Box, Flex, Text, Button } from '@bds-libs/uikit'
import { useWeb3React } from '@web3-react/core'
import Select from 'react-select'
import { ArrowDown } from 'react-feather'

import { useDerivedBridgeInfo } from 'state/bridge/hooks'
import { Field } from 'state/bridge/actions'
import { AutoColumn } from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import BridgeChainLogo from 'components/BridgeChainLogo'
import PageWithBanner from 'components/PageWithBanner'

import useAuth from 'hooks/useAuth'
import useI18n from 'hooks/useI18n'

import { BodyWrapper } from '../AppBody'
import Deposit from './Deposit'
import { BridgeChain, supportedChains, BridgeType } from '../../constants/bridgeChain'

export interface BridgeProps {}

const PageWrapper = styled(BodyWrapper)`
  max-width: 500px;
  width: 100%;
  padding: 1rem;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 2rem;
  }
`

const TruncateText = styled(Text)`
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
  }
`

export enum View {
  Bridge = 'bridge',
  Deposit = 'deposit'
}

type ViewType = View.Bridge | View.Deposit
type BridgeChainOption = BridgeChain & {
  label: string
  value: string
}

const formatChains = () =>
  supportedChains.map((chain) => ({
    label: chain.name,
    value: chain.symbol,
    ...chain
  }))

const Bridge: React.FC<BridgeProps> = () => {
  const chainOptions = useMemo(() => formatChains(), [])
  const { account: bscAccount } = useWeb3React()
  const { getKaiAccount } = useAuth()
  const { _t } = useI18n()

  const [kaiAccount, setKaiAccount] = useState<string>('')
  const [view, setView] = useState<ViewType>(View.Bridge)
  const [srcChain, setSrcChain] = useState(chainOptions[0])
  const [desChain, setDesChain] = useState(chainOptions[1])

  const { currencies, error } = useDerivedBridgeInfo('BNB')

  const currency = useMemo(() => currencies[Field.CURRENCY], [currencies])

  useEffect(() => {
    const handleGetKaiAccount = async () => {
      const account = await getKaiAccount()
      setKaiAccount(account?.[0] || '')
    }

    handleGetKaiAccount()
  }, [getKaiAccount])

  const handleGetKaiAccount = async () => {
    const account = await getKaiAccount()
    return account
  }

  const handleChangeView = useCallback(
    (view: ViewType) => () => {
      setView(view)
    },
    []
  )

  const formatOptionLabel = useCallback((option: BridgeChainOption) => {
    return (
      <Row align="center">
        <Text marginRight="0.75rem">{option.label}</Text>
        <BridgeChainLogo symbol={option.symbol} tokenAddress={option.address} />
      </Row>
    )
  }, [])

  const handleSwapChain = useCallback(() => {
    setSrcChain(desChain)
    setDesChain(srcChain)
  }, [srcChain, desChain])

  const bridgeType = useMemo(() => {
    if (srcChain.symbol === 'KAI') {
      return BridgeType.KRC_BSC
    }

    return BridgeType.BSC_KRC
  }, [srcChain])

  const selectStyles = useMemo(
    () => ({
      container: (styles: any) => ({ ...styles, width: '100%' }),
      menuList: (styles: any) => ({ ...styles, zIndex: '100' }),
      menu: (styles: any) => ({ ...styles, zIndex: '100' })
    }),
    []
  )

  return (
    <PageWithBanner
      heading={_t('bridge', 'Bridge')}
      text={_t('bridge_description', 'Transfer your token accross multi-chain')}
      src="/images/banner_bridge.png"
    >
      <PageWrapper>
        {view === View.Bridge ? (
          <AutoColumn>
            <Text fontSize="1.25rem" fontWeight="500">
              {_t('bridge_title', 'Select an asset and chains to bridge your asset to')}
            </Text>

            <Box my="2rem">
              <RowBetween alignItems="center">
                <Text>{_t('source_chain', 'Source Chain')}</Text>
                <Box width="18rem">
                  <Select
                    styles={selectStyles}
                    formatOptionLabel={formatOptionLabel}
                    options={chainOptions}
                    value={srcChain}
                    isDisabled
                  />
                </Box>
              </RowBetween>

              <Row justifyContent="center" my="1.25rem" onClick={handleSwapChain}>
                <ArrowDown size="1.25rem" cursor="pointer" />
              </Row>
              <RowBetween alignItems="center" mt="0.75rem">
                <Text>{_t('des_chain', 'Destination Chain')}</Text>
                <Box width="18rem">
                  <Select
                    styles={selectStyles}
                    formatOptionLabel={formatOptionLabel}
                    options={chainOptions}
                    value={desChain}
                    isDisabled
                  />
                </Box>
              </RowBetween>
            </Box>

            <Box marginY="1rem">
              <Text>
                {_t('your_chain_account', 'Your BSC account', {
                  chain: 'BSC'
                })}
                :{' '}
              </Text>
              <TruncateText>{bscAccount}</TruncateText>
            </Box>

            {kaiAccount ? (
              <Box>
                <Text>
                  {_t('your_chain_account', 'Your KardiaChain account', {
                    chain: 'KardiaChain'
                  })}
                </Text>
                <TruncateText>{kaiAccount}</TruncateText>
              </Box>
            ) : (
              <Flex justifyContent="center">
                <Button onClick={handleGetKaiAccount} width="240px" height="32px">
                  {_t('connect_chain_wallet', ' Connect Kai Wallet', {
                    chain: 'Kai'
                  })}
                </Button>
              </Flex>
            )}

            <Button
              mt="1.25rem"
              onClick={handleChangeView(View.Deposit)}
              disabled={error === 'Connect Wallet' || !kaiAccount || !currency}
            >
              {_t('view_bridge_info', 'View Bridge Info')}
            </Button>
          </AutoColumn>
        ) : (
          <Deposit
            goBack={handleChangeView(View.Bridge)}
            type={bridgeType}
            bscAccount={bscAccount ?? ''}
            krcAccount={kaiAccount ?? ''}
          />
        )}
      </PageWrapper>
    </PageWithBanner>
  )
}

export default Bridge
