import React from 'react'
import Banner, { BannerProps } from '../Banner'

const PageWithBanner: React.FC<BannerProps> = ({ children, ...bannerProps }) => {
  return (
    <>
      <Banner {...bannerProps} />
      {children}
    </>
  )
}

export default PageWithBanner
