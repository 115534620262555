import React, { createContext, useCallback, useState } from 'react'
import messages from 'locale/messages.json'
import { allLanguages, EN, localeStorageKey } from 'constants/localisation/languageCodes'

export interface Message {
  [key: string]: {
    [key: string]: string
  }
}

export interface LangType {
  code: string
  language: string
}

interface TranslateParams {
  [key: string]: string | number
}

interface LanguageContextProps {
  currentLanguage: LangType | undefined
  handleChangeLanguage: (lang: LangType) => void
  listMessages: Message
  _t: (key: number | string, fallback: string, params?: TranslateParams) => string
}

export const LanguageContext = createContext<LanguageContextProps>({
  currentLanguage: EN,
  handleChangeLanguage: () => {},
  listMessages: {},
  _t: () => ''
})

interface LanguageProviderProps {
  locale: string
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ locale = EN.code, children }) => {
  const [currentLanguage, setCurrentLanguage] = useState(() => allLanguages.find((lang) => lang.code === locale))

  const handleChangeLanguage = useCallback((lang: LangType) => {
    window.localStorage.setItem(localeStorageKey, lang.code)
    setCurrentLanguage(lang)
  }, [])

  const _t = useCallback(
    (key: number | string, fallback: string, params?: TranslateParams) => {
      let message = messages?.[key]?.[currentLanguage?.code || ''] || fallback
      if (!params) {
        return message
      }

      Object.entries(params).forEach(([paramKey, value]) => {
        if (paramKey) {
          message = message.replaceAll(`[${paramKey}]`, value)
        }
      })

      return message
    },
    [currentLanguage]
  )

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        handleChangeLanguage,
        listMessages: messages || {},
        _t
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
