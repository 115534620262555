import React, { useContext } from 'react'
import { Button } from '@bds-libs/uikit'
import { ArrowUpCircle } from 'react-feather'
import { ThemeContext } from 'styled-components'

import Modal from '../Modal'
import { AutoColumn } from '../Column'
import { Wrapper, Section, ConfirmedIcon, ContentHeader } from '../TransactionConfirmationModal/helpers'

interface SubmittedModalProps {
  isOpen: boolean
  onDismiss: () => void
  content: React.ReactNode
}

const TransactionSubmittedModal = ({ isOpen, onDismiss, content }: SubmittedModalProps) => {
  const theme = useContext(ThemeContext)

  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <Section>
          <ContentHeader onDismiss={onDismiss}>Transaction submitted</ContentHeader>
          <ConfirmedIcon>
            <ArrowUpCircle strokeWidth={0.5} size={97} color={theme.colors.primary} />
          </ConfirmedIcon>
          <AutoColumn gap="8px" justify="center">
            <div>{content}</div>
            <Button onClick={onDismiss} mt="20px">
              Close
            </Button>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}

export default TransactionSubmittedModal
