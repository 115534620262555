import { Currency, CurrencyAmount, Token, ETHER } from '@pancakeswap-libs/sdk-v2'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useCurrency } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'

import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'
import { useCurrencyBalances } from '../wallet/hooks'
import { Field, selectCurrency, typeInput } from './actions'

export function useBridgeState(): AppState['bridge'] {
  return useSelector<AppState, AppState['bridge']>((state) => state.bridge)
}

export function useBridgeActionHandlers(): {
  onFieldInput: (typedValue: string) => void
  onCurrencySelection: (currency: Currency) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onFieldInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY, typedValue }))
    },
    [dispatch]
  )

  const onCurrencySelection = useCallback(
    (currency: Currency) => {
      dispatch(
        selectCurrency({
          field: Field.CURRENCY,
          currencyId: currency instanceof Token ? currency.address : currency === ETHER ? 'BNB' : '',
        })
      )
    },
    [dispatch]
  )

  return {
    onFieldInput,
    onCurrencySelection,
  }
}

export function useDerivedBridgeInfo(
  defaultCurrencyAddress?: string
): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  error?: string
} {
  const { account } = useActiveWeb3React()

  const {
    independentField,
    typedValue,
    [Field.CURRENCY]: { currencyId: inputCurrencyId },
  } = useBridgeState()
  const inputCurrency = useCurrency(inputCurrencyId || defaultCurrencyAddress)

  // tokens
  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.CURRENCY]: inputCurrency ?? undefined,
    }),
    [inputCurrency]
  )

  // balances
  const balances = useCurrencyBalances(account ?? undefined, [currencies[Field.CURRENCY]])
  const currencyBalances: { [field in Field]?: CurrencyAmount } = {
    [Field.CURRENCY]: balances[0],
  }

  // amounts
  const independentAmount: CurrencyAmount | undefined = tryParseAmount(typedValue, currencies[independentField])

  const parsedAmounts: { [field in Field]: CurrencyAmount | undefined } = {
    [Field.CURRENCY]: independentAmount,
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (!parsedAmounts[Field.CURRENCY]) {
    error = error ?? 'Enter an amount'
  }

  const { [Field.CURRENCY]: currencyAmount } = parsedAmounts

  if (currencyAmount && currencyBalances?.[Field.CURRENCY]?.lessThan(currencyAmount)) {
    error = `Insufficient ${currencies[Field.CURRENCY]?.symbol} balance`
  }

  return {
    currencies,
    currencyBalances,
    parsedAmounts,
    error,
  }
}
