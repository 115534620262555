import React from 'react'
import { Box, Text } from '@bds-libs/uikit'
import styled from 'styled-components'

export interface BannerProps {
  heading?: string
  text?: string
  src?: string
}

const BannerWrapper = styled.div`
  position: absolute;
  bottom: 0.75rem;
  left: 0.75rem;

  ${({ theme }) => theme.mediaQueries.md} {
    bottom: 1.75rem;
    left: 1.75rem;

    .heading {
      font-size: 1.5rem;
    }
    .text {
      font-size: 1rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    bottom: 3rem;
    left: 3rem;

    .heading {
      font-size: 2rem;
    }
    .text {
      font-size: 1.25rem;
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    bottom: 3.5rem;
    left: 3.5rem;

    .heading {
      font-size: 3.5rem;
    }
    .text {
      font-size: 2rem;
    }
  }
`

const Banner: React.FC<BannerProps> = ({ heading, text, src }) => {
  return (
    <Box mb="2rem" position="relative">
      <img
        src={src || '/images/banner_pool.png'}
        alt="banner"
        style={{
          width: '100%',
          maxHeight: '500px',
        }}
      />
      <BannerWrapper>
        <Text fontWeight="600" className="heading" color="text">
          {heading}
        </Text>
        <Text className="text">{text}</Text>
      </BannerWrapper>
    </Box>
  )
}

export default Banner
