import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react'
import { Box, Flex, Text, Input, Button, LinkExternal } from '@bds-libs/uikit'
import { ArrowLeft } from 'react-feather'
import KardiaClient from 'kardia-js-sdk'
import { MaxUint256 } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'

import { AutoColumn } from 'components/Column'
import Row, { RowBetween } from 'components/Row'
import TransactionSubmittedModal from 'components/TransactionSubmittedModal'

import { useBSCToKaiContract, useTokenContract } from 'hooks/useContract'
import useI18n from 'hooks/useI18n'

import KaiBridgeABI from 'constants/abis/kai_bridge.json'
import ERCABI from 'constants/abis/erc20.json'
import {
  BridgeType,
  KRC_BDS_ADDRESS,
  BSC_BDS_ADDRESS,
  BDS_IN_KAI_ADDRESS,
  BDS_IN_BSC_ADDRESS
} from 'constants/bridgeChain'
import { useSingleCallResult } from 'state/multicall/hooks'

const kardiaClient = new KardiaClient({ endpoint: 'https://kai-internal-2.kardiachain.io' })
const kardiaContract = kardiaClient.contract
const kardiaTransaction = kardiaClient.transaction

export interface BridgeDepositProps {
  goBack: () => void
  type: BridgeType
  bscAccount: string
  krcAccount: string
}

const BridgeDeposit: React.FC<BridgeDepositProps> = ({ goBack, type, krcAccount, bscAccount }) => {
  const [amount, setAmount] = useState('')
  const [depositAddress, setDepositAddress] = useState('')
  const [approved, setApproved] = useState<boolean | undefined>(undefined)
  const [submmited, setSubmitted] = useState(false)
  const [submittedContent, setSubmittedContent] = useState<React.ReactNode>()
  const { _t } = useI18n()

  const bdsBSCInputs = useMemo(() => [bscAccount, BSC_BDS_ADDRESS], [bscAccount])

  const bscToKaiContract = useBSCToKaiContract(BSC_BDS_ADDRESS)

  const bdsInBSCTokenContract = useTokenContract(BDS_IN_BSC_ADDRESS, true)

  const bdsBSCAllowance = useSingleCallResult(bdsInBSCTokenContract, 'allowance', bdsBSCInputs).result

  const checkKardiaChainAllowance = useCallback(async () => {
    kardiaContract.updateAbi(ERCABI)
    const data = await kardiaContract
      .invokeContract('allowance', [krcAccount, KRC_BDS_ADDRESS])
      .call(BDS_IN_KAI_ADDRESS, {}, 'latest')

    // eslint-disable-next-line no-console
    console.log('ahihi allowance data', data)

    if (data > 0) {
      setApproved(true)
    }
  }, [krcAccount])
  const checkKardiaChainAllowanceInterval = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (type === BridgeType.BSC_KRC && bdsBSCAllowance) {
      setApproved(true)
    }
  }, [type, bdsBSCAllowance])

  useEffect(() => {
    if (type === BridgeType.KRC_BSC) {
      checkKardiaChainAllowance()
    }
  }, [type, checkKardiaChainAllowance])

  useEffect(() => {
    if (approved && checkKardiaChainAllowanceInterval.current) {
      clearInterval(checkKardiaChainAllowanceInterval.current)
    }
  }, [approved])

  useEffect(() => {
    return () => {
      if (checkKardiaChainAllowanceInterval.current) {
        clearInterval(checkKardiaChainAllowanceInterval.current)
      }
    }
  }, [])

  const handleApproveBDSInKai = async () => {
    kardiaContract.updateAbi(ERCABI)
    const data = await kardiaContract.invokeContract('approve', [KRC_BDS_ADDRESS, MaxUint256]).txData()

    await kardiaTransaction.sendTransactionToExtension(
      {
        to: BDS_IN_KAI_ADDRESS,
        gasPrice: 1,
        gas: 300000,
        data
      },
      true
    )

    checkKardiaChainAllowance()
    checkKardiaChainAllowanceInterval.current = setInterval(checkKardiaChainAllowance, 2000)
  }

  const handleApproveBDSInBSC = async () => {
    await bdsInBSCTokenContract?.approve(BSC_BDS_ADDRESS, MaxUint256, {
      gasLimit: 100000
    })
  }

  const handleApprove = () => {
    if (type === BridgeType.KRC_BSC) {
      handleApproveBDSInKai()
      return
    }

    handleApproveBDSInBSC()
  }

  const handleSubmitKaiContract = async () => {
    kardiaContract.updateAbi(KaiBridgeABI)

    const data = await kardiaContract
      .invokeContract('swapKAI2BSC', [BDS_IN_KAI_ADDRESS, depositAddress, `${BigNumber.from(+amount * 10 ** 8)}`])
      .txData()

    const tx = await kardiaTransaction.sendTransactionToExtension(
      {
        gasPrice: 1,
        gas: 100000,
        value: `${0.5 * 10 ** 18}`,
        to: KRC_BDS_ADDRESS,
        data
      },
      true
    )
    // eslint-disable-next-line no-console
    console.log('ahihi KAI_BSC tx', tx)
    setSubmitted(true)
    setSubmittedContent(
      <LinkExternal href={`https://explorer.kardiachain.io/tx/${tx.transactionHash}`}>
        View transaction on KaiExplorer
      </LinkExternal>
    )
  }

  const handleSubmit = async () => {
    if (type === BridgeType.KRC_BSC) {
      return handleSubmitKaiContract()
    }

    const tx = await bscToKaiContract?.swapBSC2KAI(
      BDS_IN_BSC_ADDRESS,
      depositAddress,
      `${BigNumber.from(+amount * 10 ** 8)}`,
      {
        gasLimit: 300000,
        value: `${0.002 * 10 ** 18}`
      }
    )
    // eslint-disable-next-line no-console
    console.log('ahihi BSC_KAI tx', tx)
    setSubmitted(true)
    setSubmittedContent(
      <LinkExternal href={`https://bscscan.com/tx/${tx.hash}`}>View transaction on BscScan</LinkExternal>
    )
    return tx
  }

  return (
    <>
      <TransactionSubmittedModal isOpen={submmited} content={submittedContent} onDismiss={() => setSubmitted(false)} />
      <AutoColumn>
        <Row>
          <ArrowLeft cursor="pointer" onClick={goBack} />
          <Flex flex={1} justifyContent="center" marginRight="1.75rem">
            <Text fontWeight="600">{_t('confirm_deposit', 'Confirm Deposit')}</Text>
          </Flex>
        </Row>
        <Box my="2rem">
          <RowBetween>
            <Text>{_t('deposit_asset', 'Deposit Asset')}</Text>
            <Box display="flex">
              <Box width="2rem" height="2rem" mt="-3px">
                <img src="https://bigbds.io/wp-content/uploads/2021/01/token100x100-2.png.webp" alt="BDS token logo" />
              </Box>
              <Text>Big Digital Shares (BDS)</Text>
            </Box>
          </RowBetween>

          <RowBetween alignItems="center" mt="1rem">
            <Text>{_t('deposit_amount', 'Deposit Amount')}</Text>
            <Box width="15rem">
              <Input onChange={(e) => setAmount(e.target.value)} />
            </Box>
          </RowBetween>
        </Box>

        <Box>
          <Text mb="0.5rem" fontWeight="600">
            {_t('deposit_address', 'Deposit Address')}
          </Text>
          <Input onChange={(e) => setDepositAddress(e.target.value)} />
          <Text my="1rem" fontSize="0.75rem">
            {_t('deposit_note', 'Your deposit will typically be credited in 10-20 minutes')}
          </Text>

          {approved ? (
            <Button onClick={handleSubmit} disabled={!amount || !depositAddress || Number.isNaN(+amount)}>
              {_t('confirm_deposit', 'Confirm Deposit')}
            </Button>
          ) : (
            <Button onClick={handleApprove} disabled={!amount || !depositAddress || Number.isNaN(+amount)}>
              {_t('approve_contract', 'Approve Contract')}
            </Button>
          )}
        </Box>
      </AutoColumn>
    </>
  )
}

export default BridgeDeposit
