import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }

    .empty-menu-link {
      flex: 1;
      opacity: 0;
      cursor: default;
      & > a {
        pointer-events: none;
      }
    }
  }
`

export default GlobalStyle
