import { MenuEntry } from '@bds-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'https://bds-dashboard.vercel.app/farms',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'https://bds-dashboard.vercel.app/pools',
  // },
  {
    label: 'Bridge',
    icon: 'BridgeIcon',
    href: '/bridge',
  },
  {
    label: '',
    icon: 'PoolIcon',
    href: '/',
    calloutClass: 'empty-menu-link',
  },
  // {
  //   label: 'Docs',
  //   icon: 'MoreIcon',
  //   href: '/docs',
  // },
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    href: '/telegram',
  },
  // {
  //   label: 'Forum',
  //   icon: 'GroupsIcon',
  //   href: '/forum',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://bds-dashboard.vercel.app/lottery',
  // },
  // {
  //   label: 'Collectibles',
  //   icon: 'NftIcon',
  //   href: 'https://bds-dashboard.vercel.app/nft',
  // },
  // {
  //   label: 'Team Battle',
  //   icon: 'TeamBattleIcon',
  //   href: 'https://bds-dashboard.vercel.app/competition',
  //   status: {
  //     text: 'CLAIM',
  //     color: 'warning',
  //   },
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://bds-dashboard.vercel.app/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://bds-dashboard.vercel.app/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://bds-dashboard.vercel.app/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://pancakeswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://pancakeswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://pancakeswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://pancakeswap.info/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: 'https://bds-dashboard.vercel.app/ifo',
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Contact',
  //       href: 'https://docs.pancakeswap.finance/contact-us',
  //     },
  //     {
  //       label: 'Voting',
  //       href: 'https://voting.pancakeswap.finance',
  //     },
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/pancakeswap',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://docs.pancakeswap.finance',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://pancakeswap.medium.com',
  //     },
  //     {
  //       label: 'Merch',
  //       href: 'https://pancakeswap.creator-spring.com/',
  //     },
  //   ],
  // },
]

export default config
